.\!bold {
    font-weight: 700 !important;
}

.w-full {
    width: 100%;
}

.text-secondary {
    color: $secondary-color-1;
}

.renovacion-mensual-input-group > .tooltip-icon {
    margin: 0 0.375rem !important;
}

.renovacion-mensual-input-group > .p-inputswitch {
    margin: 0 !important;
}

.lista-blanca-search-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .lista-blanca-input-search-group {
        position: relative;
        
        .search-icon {
            height: 16px;
            width: 16px;
            position: absolute;
            z-index: 10;
            top: 10px;
            left: 12px;
        }

        input {
            padding: 10px 16px 10px 40px !important; // 40px
            border-radius: 999px !important;
            height: 38px !important;
            width: 359px !important;
        }
    }

}

.lista-negra-input-search-group {
    input {
        width: 311px !important;
    }
}

.lista-input-search.p-field.p-col {
    margin: 0 !important;
    padding: 0 !important;
}

.formulario-cuenta-usuario-tab-view {
    .p-tabview-panels {
        padding: 14px !important;
    }

    .p-tabview-nav-link {
        .pi.pi-exclamation-triangle {
            color: #EB0A0A;
            margin-left: 0.5rem;
        }
    }
}

.grid-no-margin {
    .p-grid {
        margin: 0px !important;
    }
}

.p-card.gap-1\/4 {
    .p-card-content {
        padding: 0 !important;
        
        .p-grid {
            gap: 0.25rem;
        }
    }
}

.gap-1\/4 {
    gap: 0.25rem !important;
}

.descripcion-lista-negra-correos {
    height: 22px;
}

.descripcion-lista-negra-telefonos {
    height: 22px;
}

.lista-container {
    .lista-negra-tooltip {
        padding: 0 0 0 0.25rem !important;
        margin: 0px !important;
    }

    .p-card {
        padding: 21px 16px;
    }

    .p-card-body {
        padding: 0 !important;
    }

    .p-card-content {
        padding: 0 !important;
        
        .p-grid {
            gap: 0.875rem;
        }
    }

    .dato-header {
        .dato {
            font-size: 32px;
            font-weight: 400;
        }
        
        .label {
            font-size: 13px; 
            font-weight: 600;
        }
    }
}

.p-card.p-mb-0 {
    margin-bottom: 0 !important;
}

.mensaje-registros {
    .contador {
        color: $secondary-color-1;
        font-size: 32px;
        font-weight: 400;
    }
    
    .registros {
        font-size: 13px;
        font-weight: 600;
        padding: 10px;
    }
}

.rounded-chip {
    .p-inputtext.p-chips-multiple-container {
        border-radius: 10px;
    }
}

.p-field.p-col.fixed-chip {
    margin: 0px !important;
    padding: 0px !important;
}

.fixed-chip {

    .p-inputtext.p-chips-multiple-container {
        padding: 14px;
        overflow-y: auto;
        display:flex;
        align-items:flex-start;
        align-content:flex-start;
    }
}

.lista-negra-chip {
    .p-inputtext.p-chips-multiple-container { 
        height: 328px;
        overflow-y: auto;
    }
}

.lista-blanca-chip {
    
    .p-inputtext.p-chips-multiple-container { 
        height: 205.5px;
        overflow-y: auto;
    }

    .p-chips {
        .p-chips-token.p-highlight:has([data-status="available"]) {
            background: #D4FFD3;
            color: #3A8939;
        }
        
        .p-chips-token.p-highlight:has([data-status="not-available"]) {
            background: #E2EBF1;
            color: #7B8187;
        }
    }

    .p-invalid {
        color: #EB0A0A;
    }
}

.lista-blanca-dialog {
    .p-dialog-title {
        color: $secondary-color-1;
    }

    .mensaje-uso-mensual {
        padding: 0 0.5rem;
        margin: 0;
        font-size: 20px;
        font-weight: 700px;
        line-height: 27.28px;
    }

    .p-field {
        margin: 0;
        padding: 0;
    }

    label {
        font-weight: 400;
        font-size: 1rem; // 16px
    }
}

.message-espacio {
    margin-top: -1.5rem; 
    margin-bottom: -2rem; 
}

.fuente-mensaje {
    font-size: 0.85rem;
    text-align: justify;
    color: #007EB4;
}

.icon-with-margin {
    margin-right: 0.5rem;
    color: #007EB4;
}
.message-container {
    display: flex;
    align-items: center;
}

.p-message .p-message-wrapper {
    padding: 0.6rem .8rem !important; ; 
}
